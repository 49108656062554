var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"login-page"}},[_c('div',{attrs:{"id":"login-form"}},[_c('DxForm',{ref:"admin",attrs:{"form-data":_vm.formData,"label-mode":"outside","show-colon-after-label":false}},[_c('DxItem',{attrs:{"data-field":"username","editor-options":{height: 40}}},[_c('DxLabel',{attrs:{"text":"Tài khoản"}}),_c('DxRequiredRule',{attrs:{"message":"Vui lòng nhập tài khoản"}})],1),_c('DxItem',{attrs:{"data-field":"password","editor-options":{
                    height: 40, 
                    mode: _vm.passwordMode,
                    buttons: [{
                        name: 'showPassword',
                        location: 'after',
                        options: {
                            type: 'default',    
                            icon: _vm.passwordMode == 'text' ? 'fas fa-eye':'fas fa-eye-slash',
                            onClick: () => {
                                this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
                            },
                        },
                    }],
                }}},[_c('DxLabel',{attrs:{"text":"Mật khẩu"}}),_c('DxRequiredRule',{attrs:{"message":"Vui lòng nhập mật khẩu"}})],1),_c('DxButtonItem',{attrs:{"button-options":_vm.buttonOptions}})],1)],1),_c('DxLoadPanel',{attrs:{"visible":_vm.loading,"show-indicator":true,"show-pane":true,"shading":true,"shading-color":"rgba(0,0,0,0.4)"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }