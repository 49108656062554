var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"warning-form"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSubmited),expression:"!isSubmited"}],staticClass:"label"},[_vm._v("PHẢN HỒI")]),(!_vm.isSubmited)?_c('div',{attrs:{"id":"form"}},[_c('DxForm',{ref:"warningForm",attrs:{"form-data":_vm.formData,"label-mode":"floating","show-colon-after-label":true}},[_c('DxItem',{attrs:{"data-field":"store_name","editor-options":{ height: 40 }}},[_c('DxLabel',{attrs:{"text":"Tên cửa hàng"}}),_c('DxRequiredRule',{attrs:{"message":"Vui lòng nhập tên cửa hàng"}})],1),_c('DxItem',{attrs:{"data-field":"isActive","editor-type":"dxCheckBox"}},[_c('DxLabel',{attrs:{"text":"Ngày và giờ hiện tại"}})],1),(!this.formData.isActive)?_c('DxItem',{attrs:{"data-field":"date","editor-type":"dxDateBox","editor-options":{
                type: 'date',
                height: 40
            }}},[_c('DxLabel',{attrs:{"text":"Ngày mua hàng"}}),_c('DxRequiredRule',{attrs:{"message":"Vui lòng chọn ngày mua hàng"}})],1):_vm._e(),(!this.formData.isActive)?_c('DxItem',{attrs:{"data-field":"time","editor-type":"dxDateBox","editor-options":{
                type: 'time',
                height: 40,
                placeholder: 'vd: 10:13 AM',
                invalidDateMessage: 'Giờ không hợp lệ'
            }}},[_c('DxLabel',{attrs:{"text":"Giờ mua hàng"}}),_c('DxRequiredRule',{attrs:{"message":"Vui lòng chọn giờ mua hàng"}})],1):_vm._e(),_c('DxItem',{attrs:{"data-field":"phone_number","editor-options":{ height: 40 }}},[_c('DxLabel',{attrs:{"text":"Số điện thoại"}}),_c('DxPatternRule',{attrs:{"pattern":/(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,"message":"Số điện thoại Không hợp lệ"}}),_c('DxRequiredRule',{attrs:{"message":"Vui lòng nhập số điện thoại"}})],1),_c('DxItem',{attrs:{"data-field":"message","editor-type":"dxTextArea","editor-options":{ height: 90, maxLength: 200 }}},[_c('DxLabel',{attrs:{"text":"Lời nhắn"}})],1),_c('DxButtonItem',{attrs:{"button-options":_vm.buttonOptions}})],1)],1):_c('div',{attrs:{"id":"successful-registration"}},[_c('img',{attrs:{"src":require("@/assets/tick.png"),"alt":""}}),_c('span',[_vm._v("Gửi Phản Hồi Thành Công")])]),_c('DxLoadPanel',{attrs:{"visible":_vm.loading,"show-indicator":true,"show-pane":true,"shading":true,"shading-color":"rgba(0,0,0,0.4)"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }